@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

.montserrat-thin {
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.montserrat-extra-light {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.montserrat-light {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.montserrat-regular {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.montserrat-medium {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.montserrat-semi-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.montserrat-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.montserrat-extra-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.montserrat-black {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.montserrat-thin-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.montserrat-extra-light-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.montserrat-light-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.montserrat-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.montserrat-medium-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.montserrat-semi-bold-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.montserrat-bold-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.montserrat-extra-bold-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.montserrat-black-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-style: italic;
}
