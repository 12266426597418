.scroll-container {
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  position: relative;
}

.scroll-container::-webkit-scrollbar {
  width: 10px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #b3b3b3;
  background-clip: padding-box;
}

.image {
  width: 100%;
  display: block;
}

.Box-Initial {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Box-Initial-Column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 32px
}
